import React from 'react';
import { Blank } from 'grommet-icons';

const IconInfo = (props) => (
  <Blank viewBox="0 0 26 26" {...props}>
    <path
      style={{ stroke: 'none' }}
      xmlns="http://www.w3.org/2000/svg"
      d="M13,1A12,12,0,1,0,25,13,12,12,0,0,0,13,1Zm1.17,17.8391H12.009V16.6132H14.17Zm.9256-5.3454c-.8936.6174-1.0723.8611-1.0723,1.9335l0,.4548H12.1227v-.4058a4.8283,4.8283,0,0,1,.455-2.405c.6661-.9586,2.0959-1.105,2.0959-2.5021a1.6434,1.6434,0,0,0-1.706-1.5435A1.9181,1.9181,0,0,0,11.05,11.1541l-1.9657-.2278a5.3219,5.3219,0,0,1,.3087-1.511,3.6714,3.6714,0,0,1,3.6882-2.2419c2.0147,0,3.8344,1.2023,3.8344,3.3958A3.3718,3.3718,0,0,1,15.0956,13.4937Z"
    />
  </Blank>
);

export default IconInfo;
